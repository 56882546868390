/* Login pages elements */

.invitation-message {
    a { color: $color-link; }
    
    p + p {
        margin-top: $base *0.5;
    }
}

.logout-message {
    @include body-text();
}


.recovery {
    &__message {
        a { color: $color-link; }

        p + p {
            margin-top: $base *0.5;
        }
    }

    &__form {
        margin-top: $base*2;
    }
}
