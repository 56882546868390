/* Login pages elements */

.the-site {
    background: transparent;
}

.login-page {
    max-width: 420px;
    margin: 0 auto;
    padding: $base*3 $base*5;
    @include theme-background-color('background-body');

    &__header {
        margin-bottom: $base*5;
    }

    &__branding {
        margin-bottom: $base*8;
    }

    &__title {
        font-family: $text;
        font-size: 27px;
        font-weight: 600;
        line-height: 1.2;
        letter-spacing: -0.01em;

    }

    &__forget {
        margin-top: $base*2;
        
        a { 
            @include  utility-link();
        }
    }

    &__altlink {
        margin-top: $base*6; 
        font-size: 0.9em;

        h6 {
            @include h6-title();
            font-size: 1.2em;
            margin-bottom: $base;
        }

        a { 
            @include  utility-link();
        }
    }

    &__disclaymer {
        margin-top: $base*8;
        @include ultra-small-text();
        opacity: 0.90;

        a { 
            color: inherit;
            text-decoration: underline;
        }
        
    }
}

.login-footer { 
    width: 100%;
    height: 50px;

    @include ultra-small-text();

    &.regular {
        margin-top: 90px;
    }

    &.fixed {
        position: fixed;
        bottom: 0;
        left: 0;
    }

    &__row {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        opacity: 0.35;
    }

    &__copy {
        
        padding-right: $base;
    }

    &__terms {
        padding-left: $base;

        a {
            color: inherit;
            margin-top: $base;

        }
    }
}
