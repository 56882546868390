.login-form {

    &__error {
        
    }

    &__submit {
        margin-top: $base*4;
    }
}

